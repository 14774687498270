import Translation from 'select2/src/js/select2/translation'
import getLocale from '../helpers/locale'
import template from 'art-template/lib/template-web'

// Monkey-Patch Translation Loading according to https://github.com/select2/select2/issues/4757
Translation.loadPath = function (path) {
  if (!(path in Translation._cache)) {
    var translations = require('select2/src/js/select2/' +
      path.replace('./', ''))

    Translation._cache[path] = translations
  }
  return new Translation(Translation._cache[path])
}

import select2 from 'select2/src/js/jquery.select2'
import AllowClear from 'select2/src/js/select2/selection/allowClear'
import MultipleSelection from 'select2/src/js/select2/selection/multiple'
import SingleSelection from 'select2/src/js/select2/selection/single'
import SelectionSearch from 'select2/src/js/select2/selection/search'
import ContainerCSS from 'select2/src/js/select2/compat/containerCss'
import DropdownCSS from 'select2/src/js/select2/compat/dropdownCss'
import EventRelay from 'select2/src/js/select2/selection/eventRelay'
import Dropdown from 'select2/src/js/select2/dropdown'
import DropdownSearch from 'select2/src/js/select2/dropdown/search'
import MinimumResultsForSearch from 'select2/src/js/select2/dropdown/minimumResultsForSearch'
import CloseOnSelect from 'select2/src/js/select2/dropdown/closeOnSelect'
import AttachBody from 'select2/src/js/select2/dropdown/attachBody'
import Utils from 'select2/src/js/select2/utils'

import $ from 'jquery'
import _ from 'lodash'

import { documentReady, elementReady } from '../ReadyListener'

documentReady((body) => {})

elementReady(($root) => {
  $root.find('.c-dropdown--container').each(function (i, el) {
    let $el = $(el)

    /* Extract all options from HTML-Container */
    const remoteUrl = $el.data('url')
    const remoteMinLength = $el.data('remote-min-length')
    const dependsOn = $el.data('depends-on')
    const dependsOnName = $el.data('depends-on-name') || $el.data('depends-on')

    const dependsOnSplitted = dependsOn
      ? _.map(_.split(dependsOn, ','), (s) => _.trim(s))
      : null
    const dependsOnNameSplitted = dependsOnName
      ? _.map(_.split(dependsOnName, ','), (s) => _.trim(s))
      : null

    const openOnChangedDependency = $el.data('open-on-changed-dependency')
    const appendEmptyOption = $el.data('append-empty-option')
    const multiselect = $el.data('multiselect')
    const multiselectStyle = $el.data('multiselect-style')
    const contentId = $el.data('content-id')
    const ddStyle = $el.data('ddstyle')
    const maximumSelectionLength = $el.data('maximum-selection-length')
    const closeOnSelect = $el.data('close-on-select')
    let content = null

    if (contentId) {
      content = $root.find('#' + contentId)
      if (content.length == 0) {
        content = null
      } else {
        content = content[0].innerHTML
      }
    }

    /* This is the actual <select> Tag */
    const $select = $el.find('select')

    /* Save initial options */
    const initialOptions = _.map($select.find('option'), (e) => [
      $(e).attr('value') || '',
      $(e).text(),
    ])

    /* Subclass and Patch MultipleSelection rendering */
    let MyMultipleSelection = function MyMultipleSelection($element, options) {
      MultipleSelection.call(this, $element, options)
    }
    MyMultipleSelection.prototype = Object.create(MultipleSelection.prototype)
    MyMultipleSelection.prototype.constructor = MultipleSelection
    MyMultipleSelection.prototype.render = function () {
      var $selection = MultipleSelection.__super__.render.call(this)

      $selection.addClass('select2-selection--multiple')

      $selection.html('<div class="select2-selection__rendered"></div>')
      this.placeholder = {
        id: '',
      }

      return $selection
    }
    // MyMultipleSelection.prototype.selectionContainer = function () {
    //   var $container = $('<span class="select2-selection__choice">' + '</span>')

    //   Utils.StoreData($container[0], 'element', $select)

    //   return $container
    // }

    /* Subclass and Patch the Search Component for Autocomplete Fields: We want to disable the "remove non - selected options" behaviour on pressing backspace in an empty search box */
    let AutoCompleteSearch = function AutoCompleteSearch(
      decorated,
      $element,
      options,
    ) {
      SelectionSearch.call(this, decorated, $element, options)
    }
    AutoCompleteSearch.prototype = Object.create(SelectionSearch.prototype)
    AutoCompleteSearch.prototype.constructor = AutoCompleteSearch
    AutoCompleteSearch.prototype.searchRemoveChoice = function (
      decorated,
      item,
    ) {
      this.$search.val('')
      this.handleSearch()
    }

    /* Now we construct the Options. The methods for choosing the selectionAdapter and drospdownAdapter
    are taken from the libraries defaults and adapted to the different variants we want to use */

    const injectSelectionAdapter = (options) => {
      if (options.multiple) {
        options.selectionAdapter = MyMultipleSelection
      } else {
        if (ddStyle == 'autocomplete') {
          options.selectionAdapter = Utils.Decorate(
            MyMultipleSelection,
            AutoCompleteSearch,
          )
          options.selectionAdapter = Utils.Decorate(
            options.selectionAdapter,
            AllowClear,
          )
        } else if (ddStyle == 'select') {
          options.selectionAdapter = SingleSelection
        }
      }

      // We dont need the placeholder mixing, as we are using the label for that

      if (options.allowClear) {
        options.selectionAdapter = Utils.Decorate(
          options.selectionAdapter,
          AllowClear,
        )
      }

      if (
        options.containerCssClass != null ||
        options.containerCss != null ||
        options.adaptContainerCssClass != null
      ) {
        options.selectionAdapter = Utils.Decorate(
          options.selectionAdapter,
          ContainerCSS,
        )
      }

      options.selectionAdapter = Utils.Decorate(
        options.selectionAdapter,
        EventRelay,
      )
      return options
    }

    const injectDropdownAdapter = (options) => {
      if (multiselect || ddStyle == 'select') {
        var SearchableDropdown = Utils.Decorate(Dropdown, DropdownSearch)
        options.dropdownAdapter = SearchableDropdown
      } else if (ddStyle == 'autocomplete') {
        options.dropdownAdapter = Dropdown
      }

      if (options.minimumResultsForSearch !== 0) {
        options.dropdownAdapter = Utils.Decorate(
          options.dropdownAdapter,
          MinimumResultsForSearch,
        )
      }

      if (options.closeOnSelect) {
        options.dropdownAdapter = Utils.Decorate(
          options.dropdownAdapter,
          CloseOnSelect,
        )
      }

      if (
        options.dropdownCssClass != null ||
        options.dropdownCss != null ||
        options.adaptDropdownCssClass != null
      ) {
        options.dropdownAdapter = Utils.Decorate(
          options.dropdownAdapter,
          DropdownCSS,
        )
      }

      options.dropdownAdapter = Utils.Decorate(
        options.dropdownAdapter,
        AttachBody,
      )

      return options
    }

    let options = {
      language: getLocale(),
      width: '100%',
      multiple: multiselect,
      closeOnSelect: closeOnSelect,
      dropdownCssClass: 'c-dropdown--select2-dropdown',
      templateSelection: function (opt) {
        let div = $('<div></div>')
        div.addClass('c-dropdown--selection-div')
        div.text(opt.text)

        if (ddStyle == 'autocomplete') {
          div.css('opacity', 0.0)

          setTimeout(() => {
            let input = $el.find('input')

            if (input.length > 0 && input[0].value.length > 0) {
              div.css('opacity', 0.0)
            } else {
              div.css('opacity', 1.0)
            }
          }, 150)
        }

        return div
      },
      templateResult: function (opt) {
        if (content != null) {
          return $(template.render(content, opt))
        } else {
          return opt.text
        }
      },
      ajax: remoteUrl
        ? {
            // The number of milliseconds to wait for the user to stop typing before
            // issuing the ajax request.
            delay: 250,
            // You can craft a custom url based on the parameters that are passed into the
            // request. This is useful if you are using a framework which has
            // JavaScript-based functions for generating the urls to make requests to.
            //
            // @param params The object containing the parameters used to generate the
            //   request.
            // @returns The url that the request should be made to.
            url: function (params) {
              return remoteUrl
            },
            // You can pass custom data into the request based on the parameters used to
            // make the request. For `GET` requests, the default method, these are the
            // query parameters that are appended to the url. For `POST` requests, this
            // is the form data that will be passed into the request. For other requests,
            // the data returned from here should be customized based on what jQuery and
            // your server are expecting.
            //
            // @param params The object containing the parameters used to generate the
            //   request.
            // @returns Data to be directly passed into the request.
            data: function (params) {
              let queryParameters = {
                term: params.term,
              }
              $select.data('searchterm', params.term)

              if (dependsOn) {
                for (let i = 0; i < dependsOnSplitted.length; i++) {
                  const sel = $('#' + dependsOnSplitted[i])
                  //debugger;

                  if (!sel) {
                    console.error(
                      'This Dropdown depends on ' +
                        dependsOnSplitted[i] +
                        ', which cannot be found.',
                      $select,
                    )
                  } else {
                    queryParameters[dependsOnNameSplitted[i]] = sel.val()
                  }
                }
              }

              return queryParameters
            },
            // You can modify the results that are returned from the server, allowing you
            // to make last-minute changes to the data, or find the correct part of the
            // response to pass to Select2. Keep in mind that results should be passed as
            // an array of objects.
            //
            // @param data The data as it is returned directly by jQuery.
            // @returns An object containing the results data as well as any required
            //   metadata that is used by plugins. The object should contain an array of
            //   data objects as the `results` key.
            processResults: function (data) {
              const transformed = {
                results: _.map(data.items, (opt) => {
                  return {
                    ...opt,
                    id: opt.value,
                    text: opt.label,
                  }
                }),
              }

              let searchterm = _.toLower($select.data('searchterm'))
              if (!searchterm || searchterm.length == 0) {
                _.each(initialOptions, (o) => {
                  if (!_.find(transformed['results'], (x) => x.id == o[0])) {
                    transformed['results'] = [
                      {
                        id: o[0],
                        text: o[1],
                      },
                      ...transformed['results'],
                    ]
                  }
                })
              } else {
                _.each(initialOptions, (o) => {
                  if (
                    !_.find(transformed['results'], (x) => x.id == o[0]) &&
                    o[1].indexOf(searchterm) != -1
                  ) {
                    transformed['results'] = [
                      {
                        id: o[0],
                        text: o[1],
                      },
                      ...transformed['results'],
                    ]
                  }
                })
              }

              if (appendEmptyOption) {
                if (!_.some(transformed.results, (opt) => opt.id == '')) {
                  transformed.results = [
                    {
                      id: '',
                      text: '',
                    },
                    ...transformed.results,
                  ]
                }
              }

              /*let searchterm = _.toLower($select.data('searchterm'));
          if (searchterm && searchterm.length > 0) {
            transformed.results = _.filter(transformed.results, (x) => {
              return _.toLower(x.text).indexOf(searchterm) != -1;
            })
          }*/

              /*_.each(transformed.results, (o) => {
            if (!_.find($select.find("option"), (oe) => o.id == $(oe).attr('value'))) {
              let opt = $("<option></option>");
              opt.attr('value', o.id);
              opt.text(o.text);
              $select.append(opt);
            }
          });*/

              //console.log("transformed", transformed)

              return transformed
            },
            // You can use a custom AJAX transport function if you do not want to use the
            // default one provided by jQuery.
            //
            // @param params The object containing the parameters used to generate the
            //   request.
            // @param success A callback function that takes `data`, the results from the
            //   request.
            // @param failure A callback function that indicates that the request could
            //   not be completed.
            // @returns An object that has an `abort` function that can be called to abort
            //   the request if needed.
            transport: function (params, success, failure) {
              var $request = $.ajax(params)

              $request.then(success)
              $request.fail(failure)

              return $request
            },

            // We want JQuery to serialize GET parameter-arrays using its raw name, not appending [] !
            traditional: true,
          }
        : null,
    }

    if (remoteMinLength && remoteUrl) {
      options.minimumInputLength = parseInt(remoteMinLength)
    } else {
      options.minimumInputLength = 0
    }

    options = injectSelectionAdapter(options)
    options = injectDropdownAdapter(options)
    options.maximumSelectionLength = maximumSelectionLength
    // options.closeOnSelect = closeOnSelect
    if (!remoteUrl && appendEmptyOption) {
      const $emptyOption = $select.find("option[value='']")
      if ($emptyOption.length == 0) {
        let selected = $select.find('option[selected]').length == 0
        $select.prepend(
          $("<option value='' disabled hidden></option>").attr(
            'selected',
            selected,
          ),
        )
      }
    }

    /* Finally, initialize the component! */
    $select.select2(options)

    /* For styling, we would like to set an "is-empty" class on the container element */
    let updateEmptyClass = () => {
      const selectedArr = _.filter($select.select2('data'), (x) => {
        return x.element.value != ''
      })
      if (selectedArr.length > 0) {
        $el.addClass('c-dropdown--container-not-empty')
        $el.removeClass('c-dropdown--container-empty')
      } else {
        $el.addClass('c-dropdown--container-empty')
        $el.removeClass('c-dropdown--container-not-empty')
      }
      $el.parent().removeClass('tmpierepaintfix')
      $el.parent().addClass('tmpierepaintfix')
      $el.parent().removeClass('tmpierepaintfix')
    }

    $select.on('change', function (e) {
      updateEmptyClass()
    })

    // Set this once at the beginning
    updateEmptyClass()

    /* Also add a class on focus */
    $select.on('select2:opening', function (event) {
      $el.addClass('c-dropdown--container-focus')
      $el.removeClass('c-dropdown--container-no-focus')
      updateEmptyClass()
    })
    $select.on('select2:closing', function (event) {
      $el.removeClass('c-dropdown--container-focus')
      $el.addClass('c-dropdown--container-no-focus')
      updateEmptyClass()

      $el.find('.c-dropdown--selection-div').css('opacity', 1.0)
    })

    /* When we use the autocomplete-style, we want to hide the selection when we are typing s.th. */
    if (ddStyle == 'autocomplete') {
      $el.on('input', 'input', (ev) => {
        if (ev.target.value.length > 0) {
          $(el).find('.c-dropdown--selection-div').css('opacity', 0.0)
        } else {
          $(el).find('.c-dropdown--selection-div').css('opacity', 1.0)
        }
      })

      /* We also want to focus after clicking the clear button*/
      $select.on('select2:clear', function () {
        setTimeout(() => {
          $el.find('input').focus()
        }, 500)
      })
    }

    /* For the multiselect-variant, we want to add an action-bar for "select-all", "select-none"
     * When changing the selection while the dropdown is open, we need to force a repaint of the options. */
    const forceOptionRepaint = () => {
      const data = $select.data('select2')
      data.results.setClasses()
    }

    $select.on('select2:open', function (event) {
      const $dd = $('.c-dropdown--select2-dropdown')

      // if (multiselect) {
      // $dd.find('.c-dropdown--action-bar').remove()
      // const actionBar = $('<div class="c-dropdown--action-bar"></div>')
      // const btnAll = $('<div class="c-dropdown--action-bar-all"></div>')
      // const btnNone = $('<div class="c-dropdown--action-bar-none"></div>')
      // btnAll.click(function () {
      //   const allOptions = _.map($select.find('option'), (o) =>
      //     $(o).attr('value'),
      //   )
      //   $select.val(allOptions)
      //   $select.trigger('change')
      //   forceOptionRepaint()
      // })
      // btnNone.click(function () {
      //   $select.val([])
      //   $select.trigger('change')
      //   forceOptionRepaint()
      // })
      // actionBar.append(btnAll)
      // actionBar.append(btnNone)
      // $dd.prepend(actionBar)
      // }
    })
    $select.on('select2:closing', function (event) {
      $select.data('searchterm', '')
    })

    if (dependsOn) {
      for (let i = 0; i < dependsOnSplitted.length; i++) {
        const sel = $('#' + dependsOnSplitted[i])
        sel.on('change', () => {
          $select.val([])
          $select.trigger('change')
          forceOptionRepaint()
          updateEmptyClass()

          if (openOnChangedDependency) {
            $select.select2('open')
            setTimeout(() => {
              $el.find('input').focus()
            }, 500)
          }
        })
      }
    }

    /* Hidden fields for label and value */
    $select.on('change', () => {
      setValueAndLabelFields($el)
    })
    setValueAndLabelFields($el)
  })
})

const setValueAndLabelFields = ($el) => {
  const $select = $el.find('select')
  const data = $select.select2('data')
  let label = ''
  if (data[0] && data[0].label) {
    label = data[0].label
  } else {
    label = $select.find('option:selected').text()
  }

  let name = $el.data('base-name')
}
