import $ from 'jquery';
import {documentReady, elementReady, executeElementReadyListenersFor} from '../ReadyListener';
import { confirmDialog } from '../helpers/dialog';
import { openDialog, closeDialog } from '../components/dialog';

documentReady((body)=>{

})

elementReady(($root)=>{
  $root.findWithRoot("button, a").each((i, el) => {
    const formId = $(el).data('form-id');
    const name = $(el).attr("name");
    const value = $(el).attr("value");
    const href = $(el).attr("href");
    const confirm_text = $(el).data("confirm-text");
    const confirm_title = $(el).data("confirm-title");
    const post_data = $(el).data("post-data");

    const ajahUrl = $(el).data('load-ajah-url');
    const ajahPrependTo = $(el).data('load-ajah-prepend-to');
    const ajahUpdateTo = $(el).data('load-ajah-to-update');

    const openDialogId = $(el).data('open-dialog');
    const doCloseDialog = $(el).data('close-dialog');

    if(formId) {
      $(el).click((ev)=>{
        ev.preventDefault();
        ev.stopPropagation();

        const $form = $('#'+formId);

        if(name && value) {
          let hiddenInput = $form.find("input[name=" + name + "]");
          if (!hiddenInput.length > 0) {
            hiddenInput = $("<input type='hidden' />")
            $form.append(hiddenInput);
          }
          hiddenInput.attr('name', name).attr('value', value);
        }

        if(confirm_text || confirm_title) {
          confirmDialog({
            title: confirm_title,
            text: confirm_text,
            onConfirm: () => {
              $form.submit();
            },
            onCancel: () => {

            },
            type: 'warning'
          });
        } else {
          $form.submit();
        }
      })
    }

    if(openDialogId) {
      $(el).click((ev)=> {
        ev.preventDefault();
        ev.stopPropagation();

        openDialog({
          id: openDialogId
        })
      });
    }

    if(doCloseDialog) {
      $(el).click((ev)=> {
        ev.preventDefault();
        ev.stopPropagation();

        closeDialog()
      });
    }

    if(ajahUrl) {
      $(el).click((ev)=> {
        ev.preventDefault();
        ev.stopPropagation();

        $.get(ajahUrl, function(data) {
          const $response = $(data);
          const topId = $response.attr("id");
          $('#'+topId).remove();
          if(ajahPrependTo) {
            $(ajahPrependTo).prepend($response);
          } else if(ajahUpdateTo) {
            $(ajahUpdateTo).html($response);
          }
          executeElementReadyListenersFor($response);
        })

      });
    }

    if(post_data) {
      $(el).click((ev)=>{
        ev.preventDefault();
        ev.stopPropagation();

        const $form = $('<form method="POST"></form>');
        $form.attr('action', href);
        $form.css('display', 'none');

        for(let name in post_data) {
          let inp = $("<input type='hidden' />")
          inp.attr('value', post_data[name]);
          inp.attr('name', name);
          $form.append(inp);
        }

        $("body").append($form);

        if(confirm_text || confirm_title) {
          confirmDialog({
            title: confirm_title,
            text: confirm_text,
            onConfirm: ()=>{
              $form.submit();
            },
            onCancel: ()=>{

            },
            type: 'warning'
          });
        } else {
          $form.submit();
        }
      })
    }
  })
});

