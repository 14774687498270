import $ from 'jquery';
import URLService from '../helpers/url';
import {documentReady, elementReady} from '../ReadyListener';

documentReady((body)=>{
  $('body').on('click', '*[data-activate-tab-name]', (ev)=>{
      const el = ev.target;
      const name = $(el).data('activate-tab-name');
      const set_id = $(el).data('activate-tab-set-id');
      activateTab(set_id, name)
  })
});

elementReady(($root)=>{
  $root.findWithRoot('.c-tabs').each(function() {
    const $tabSet = $(this);
    const $tabs= $tabSet.find(".c-tabs--tab");
    const tabIds = $tabs.map((elem) => $(elem).attr('id'));

    updateIndicator($tabSet, $tabSet.find('.c-tabs--tab.c-tabs--active').first());
    setupTabChangeListener($tabSet);
  })
});

const updateIndicator = ($tabSet, activeTab) => {
  const activeTabNr = $(activeTab).index(".c-tabs--tab");

  const $tabBarIndicatorInner = $tabSet.find(".c-tabs--tabbar-indicator-inner");
  const $tabNavItems = $tabSet.find('.c-tabs--nav > li');
  const $activeTabNavItem = $($tabNavItems[activeTabNr]);
  const left = $activeTabNavItem[0].getBoundingClientRect().left - $activeTabNavItem.parent()[0].getBoundingClientRect().left - 10;

  $tabBarIndicatorInner.css('left', left);
  $tabBarIndicatorInner.css('width', $activeTabNavItem[0].offsetWidth);
}


const setupTabChangeListener = ($tabSet) => {
  $tabSet.on('tabsbeforeactivate', (event, ui) => {
    setTimeout(()=>{
      updateIndicator($tabSet, ui.newTab);
    })
  })
}

const activateTab = (set_id, name) => {
  const $tabSet = $('.c-tabs[data-set-id='+set_id+']').first();
  const $tab = $tabSet.find('.c-tabs--tab[data-name='+name+']').first();
  $tab.parent().children().each((i,el)=>{
    const $el = $(el);
    if($el.is($tab)) {
      $el.show();
      $el.addClass('c-tabs--active')
    } else if($el.hasClass("c-tabs--tab")) {
      $el.hide();
      $el.removeClass('c-tabs--active')
    }
  })
  updateIndicator($tabSet, $tab);

  const newUrl = URLService.setQueryParameter(set_id, name);
  history.replaceState(null, null, newUrl);
}
