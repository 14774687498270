import $ from 'jquery'

// Polyfills and Plugins
import './polyfills/input-empty'
import './polyfills/jquery-find'
import './polyfills/replaceTag'

// Components
import './components/datetimepicker'
import './components/form'
import './components/input'
import './components/navigation'
import './components/checkbox'
import './components/radio'
import './components/dropdown'
import './components/button'
import './components/panel'
import './components/table'
import './components/tabs'
import './components/snackbar'
import "./components/languageSelection"

// Styles Entry Point (Webpack)
import '../styles/main.scss'

import { initialExecuteListeners } from './ReadyListener'
import { initLocale } from './lang'

const defaultConfig = {
  translations: {
    //put id-value pairs
  },
  // auto detect locale
  locale: window.navigator.userLanguage || window.navigator.language,
}

// Hooks to control and react on the js lifecycle
window.appDfd = $.Deferred()
window.appSetup = function (config) {
  window.appConfig = {
    ...defaultConfig,
    ...config,
  }

  $().ready(() => {
    initialExecuteListeners()
    window.appDfd.resolve()
  })

  // Fully supporting the back-button for ajah-tables is difficult -> quick workaround: reload after backbutton is pressed
  $(window).bind('popstate', function () {
    location.reload()
  })
}

window.appReady = function (f) {
  window.appDfd.then(
    () => {
      f()
    },
    () => {
      throw new Error('error while executing appReady callback')
    },
  )
}
