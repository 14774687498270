import $ from 'jquery';

import {documentReady, elementReady} from '../ReadyListener';

documentReady((body)=>{

});


elementReady(($root) => {
    $('#c-navigation--language-selection').on('select2:select', function (e) {
      const data = e.params.data;
      document.cookie = `language=${data.id};`;
      location.reload();
  });
  const options = $($root).find('#c-navigation--language-selection').find("option");
})