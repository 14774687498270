import $ from 'jquery';

import {documentReady, elementReady} from '../ReadyListener';
import './snackbar.lib.js';

documentReady((body)=>{

});

elementReady(($root)=>{
  $root.findWithRoot('.c-snackbar').each(function() {
    const $el = $(this);
    const type = $el.data("type");
    const content = $el.data("content");

    var options =  {
      content: content, // text of the snackbar
      style: "c-snackbar--"+type, // add a custom class to your snackbar
      timeout: 2000, // time in milliseconds after the snackbar autohides, 0 is disabled
      onClose: function(){ } // callback called when the snackbar gets closed.
    }

    $.snackbar(options);
  })
});
