import $ from 'jquery';

import {documentReady, elementReady} from '../ReadyListener';

documentReady((body)=>{

});

elementReady(($root)=>{

});
