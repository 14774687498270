import swal from 'sweetalert2'

export function confirmDialog(opts) {
  const default_opts = {
    'type': 'warning',
    'title': '',
    'text': ''
  };
  const combined_opts = {
    ...default_opts,
    ...opts
  };
  const swal_opts = {
    title: combined_opts.title || '',
    text: combined_opts.text || '',
    // type: combined_opts.type || 'warning',
    showCancelButton: true,
    confirmButtonColor: '#009ddc',
    cancelButtonColor: '#d33',
  };
  swal.fire(swal_opts).then((result) => {
    if (result.value) {
      opts.onConfirm();
    }
  })
}