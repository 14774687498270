import $ from 'jquery';
import URLService from '../helpers/url';
import URLService2 from '../helpers/url2';
import {documentReady, elementReady, executeElementReadyListenersFor} from '../ReadyListener';

documentReady((body)=>{

  setupEditing(body);

});

elementReady(($root)=>{
  $root.findWithRoot(".c-table--table").each((i, el)=> {
    setupTdWidths($(el));
    setupSorting($(el));
    setupPagination($(el));
    setupEditingInitialVisibility($(el));
  })
});

const setupTdWidths = ($table) => {
  const ths = $table.find(".c-table--th");
  const trs = $table.find(".c-table--tbody .c-table--tr");
  $.each(trs, (h, tr)=> {
    const tds = $(tr).find(".c-table--td");
    $.each(tds, (i, td) => {
      const ix = $(td).index();
      const th = ths[ix];
      const thClasses = th.className.split(/\s+/);
      $.each(thClasses, (j, class_) => {
        if(class_.indexOf("o-col-")!=-1 && !$(td).hasClass(class_)) {
          $(td).addClass(class_);
        }
      });
    });
  });
}

const setupSorting = ($table) => {
  $table.findWithRoot(".c-table--th[data-sortable=1]").each((i, th)=>{
    const $th = $(th);
    $th.addClass("c-table--sortable");
    const thName = $(th).data("name");
    const queryKey = $table.attr("name")+".sort";

    if(URLService.getQueryParameterAsString(queryKey) == thName+".asc") {
      $th.addClass("c-table--sorted-asc");
    }

    if(URLService.getQueryParameterAsString(queryKey) == thName+".desc") {
      $th.addClass("c-table--sorted-desc");
    }

    $th.click(()=>{
      if(URLService.getQueryParameterAsString(queryKey) == thName+".asc") {
        URLService.redirect(
          URLService.setQueryParameter(queryKey, thName+".desc")
        );
      } else {
        URLService.redirect(
          URLService.setQueryParameter(queryKey, thName+".asc")
        );
      }
    })
  });
}

const setupPagination = ($table) => {
  const $base = $table.parents('.c-table--base');
  const name = $table.attr("name");
  $base.find('.c-table--paginate-link').click(function(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const href = $(ev.target).attr("href");

    const us = new URLService2(href);
    us.setQueryParameter(name+".ajah","1");
    const hrefPatched = us.url;

    $.get(hrefPatched, (data)=>{
      const $response = $(data);
      $base.replaceWith($response);
      executeElementReadyListenersFor($response);

      const limit = us.getQueryParameterAsInt(name+".limit");
      const offset = us.getQueryParameterAsInt(name+".offset");

      const usCurrent = new URLService2();
      usCurrent.setQueryParameter(name+".limit", limit);
      usCurrent.setQueryParameter(name+".offset", offset);
      history.pushState(null, null, usCurrent.url);
    });
  })
}


const showForm = ({$button, $tr}) => {
    $tr.find(".c-table--td").each((i, td)=> {
        const $content = $(td).find(".c-table--td-content");
        const $edit = $(td).find(".c-table--td-edit");
        if ($edit.length > 0) {
            $content.hide();
            $edit.show();
        }
    });

    $button.addClass("fa-save");
    $button.removeClass("fa-edit");
    $button.data('form-loaded', true);

    $tr.find('.c-table--editrow-btn-cancel').show();
    $tr.find('.c-button--delete').hide();
}

const hideForm = ({$tr}) => {
    $tr.find(".c-table--td").each((i, td)=> {
        const $content = $(td).find(".c-table--td-content");
        const $edit = $(td).find(".c-table--td-edit");
        if ($edit.length > 0) {
            $content.show();
            $edit.hide();
        }
    });
    $tr.find('.c-table--editrow-btn-cancel').hide();
    $tr.find('.c-button--delete').show();
    $tr.find('.c-table--editrow-btn').removeClass("fa-save");
    $tr.find('.c-table--editrow-btn').addClass("fa-edit");
    $tr.find('.c-table--editrow-btn').data('form-loaded', false);
}

const setupEditing = (body) => {
  $(body).on('click', '.c-table--editrow-btn', function(ev) {

    const $button = $(this);
    const $tr = $button.parent().parent();
    const form_loaded = $button.data('form-loaded');

    // hide all other edit rows
    $tr.parent().find(">*").each(function(i, otr) {
        hideForm({$tr: $(otr)});
    })


    if(!form_loaded) {
      showForm({$button, $tr});
    } else {
      $tr.submit();
    }

    ev.preventDefault();
    ev.stopPropagation();

    return false;
  });

  $(body).on('click', '.c-table--editrow-btn-cancel', function(ev) {

    const $tr = $(this).parent().parent();

    hideForm({$tr});

    ev.preventDefault();
    ev.stopPropagation();

    return false;
  })
}

const setupEditingInitialVisibility = ($table) => {

    $table.find('.c-table--tr-with-actions-initialvisibleform').each((i,tr)=>{
        const $tr = $(tr);
        const $button = $tr.find('.c-table--editrow-btn');
        showForm({$button, $tr});
    })
}