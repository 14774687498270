import $ from 'jquery';
import {documentReady, elementReady} from '../ReadyListener';
import {getMoment} from '../helpers/locale';

elementReady(($root)=>{
  $root.findWithRoot('.c-navigation--modal').click(()=> {
    $('body').toggleClass('c-navigation--active');
    $('body').toggleClass('c-navigation--inactive');
  });
  
  $root.findWithRoot('.c-navigation--burger-btn').click(() => {
      $('body').toggleClass('c-navigation--active');
      $('body').toggleClass('c-navigation--inactive');
  });
  
  $root.findWithRoot('.c-navigation--toplist a').click(function(ev) {
      let $a = $(this);
      let $li = $a.parent();
      let $collapsible = $li.findWithRoot('.c-navigation--collapsible');
      let $sublist = $li.findWithRoot('.c-navigation--sublist');
      
      if($collapsible.length>0) {
          $collapsible.toggleClass('c-navigation--expand');
          $collapsible.toggleClass('c-navigation--collapse');
          $sublist.toggleClass('c-navigation--sublist-active');
          ev.preventDefault();
          return false;
      } else {
          return true;
      }
  });

  $root.findWithRoot('.c-navigation--clock').each(function() {

    const $cl = $(this);
    const startTime = function() {
      let ds = getMoment().format("L");
      let hs = getMoment().format("LTS");
      $cl.html($('<div class="c-navigation--clock-time"></div>').html(hs));
      $cl.append($('<div class="c-navigation--clock-date"></div>').html(ds));
      var t = setTimeout(startTime, 500);
    }
    startTime();
  });
});
