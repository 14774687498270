import $ from 'jquery';

import Inputmask from "inputmask";
import hyperform from "hyperform/dist/hyperform.cjs";

import jQueryForm from "jquery-form";


import {documentReady, elementReady, executeElementReadyListenersFor} from '../ReadyListener';

/*
* The form element supports a few data-attributes to set different behaviour
* - data-to-update=#id - when we submit an ajax-form, we can inject the resulting html into the dom
* - data-to-replace=#id
* - data-focus=#id - focus a specific input element on document ready
* */

documentReady((body)=>{
  $('form[data-focus]').each((i,el)=>{
    if(!window.location.hash) {
      $(el).find(":input#"+$(el).data('focus')).focus();
    }
  })
})

elementReady(($root)=>{
  // Setup Hyperform Validation
  hyperform($root[0], {
    classes: {
      warning: "o-hyperform--warning",
      valid: "o-hyperform--valid",
      invalid: "o-hyperform--invalid",
      validated: "o-hyperform--validated"
    }
  });

  // Set custom validation error
  $root.findWithRoot("input[data-initial-error]").each((i,el) => {
    $(el).setCustomValidity($(el).data("initial-error"));
  });

  // Ajax-Form Support
  $root.findWithRoot("form[data-to-update], form[data-to-replace]").each((i,el) => {
    let $form = $(el);

    let toUpdate = $(el).data('to-update');
    let toReplace = $(el).data('to-replace');

    let success_method = function(data) {
      if(toUpdate) {
        $(toUpdate).html(data);
        executeElementReadyListenersFor(toUpdate);
      }
      if(toReplace) {
        $(toReplace).replaceWith(data);
        executeElementReadyListenersFor(toReplace);
      }
    };

    $form.ajaxForm({
      success: success_method,
      complete: function() {
        $form.trigger("submitted");
      }
    });

  });
});
