import $ from 'jquery';

import {documentReady, elementReady} from '../ReadyListener';

documentReady((body)=>{

});

elementReady(($root)=>{
    $root.findWithRoot('.c-radio--virtualradio').click(function() {
        $(this).prev().prop("checked", true);
    })
});