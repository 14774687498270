import $ from 'jquery';
import moment from 'moment';
import {documentReady, elementReady} from '../ReadyListener';
import {getLocale} from '../lang';
import {forceEmptyClass} from '../polyfills/input-empty';

import './datetimepicker.lib';

elementReady(($root)=>{
  
  const weekStart = moment().locale(getLocale()).localeData().firstDayOfWeek();

  const defaultOptions = {
    cancelText: 'Cancel',
    okText: 'OK',
    clearText: 'Clear',
    nowText: 'Now',
    weekStart: weekStart,
    disabledDays: [],
    minDate: null,
    maxDate: null,
    currentDate: null,
    lang: 'de'
  };
  
  $root.findWithRoot('input[type=date]').each((i,el)=>{
    const disabled = $(el).attr("disabled");
    const $el = $(el);
    if(disabled) {
      const $container = $(el).parent();
      $el.attr('type','hidden');
      const $dateDisabled = $('<input type="text" disabled="disabled" class="c-input--text c-input--time-disabled">');
      $container.append($dateDisabled);
      $dateDisabled.val(moment($el.val()).format("YYYY-MM-DD"));
    } else {
      $(el).materialDatePicker({
        ...defaultOptions,
        'time': false,
        'format': 'YYYY-MM-DD'
      });
    }
  });
  $root.findWithRoot('input[type=time]').each((i,el)=>{
    const $el = $(el);
    const disabled = $(el).attr("disabled");

    if(disabled) {
      const $container = $(el).parent();
      $el.attr('type','hidden');
      const $dateDisabled = $('<input type="text" disabled="disabled" class="c-input--text c-input--time-disabled">');
      $container.append($dateDisabled);
      $dateDisabled.val(moment($el.val()).format("HH:mm"));
    } else {
      $(el).materialDatePicker({
        ...defaultOptions,
        'time': true,
        'date': false,
        'format': 'HH:mm'
      });
    }
  });

  $root.findWithRoot('input[data-type=datetime]').each((i,el)=>{
    const $el = $(el);
    const disabled = $el.attr('disabled');

    if(!disabled) {
      const $container = $(el).parent();
      $el.attr('type','hidden');
      const $datePicker = $('<input type="text" class="c-input--text c-input--datetime-date">');
      const $timePicker = $('<input type="text" class="c-input--text c-input--datetime-time">');
      $container.append($datePicker);
      $container.append($timePicker);

      if($el.val()) {
        const initialD = moment($el.val());
        $datePicker.val(initialD.format('YYYY-MM-DD'));
        $timePicker.val(initialD.format('HH:mm'));
      }
      let date = $datePicker.val();
      let time = $timePicker.val();

      const updateDT = () => {
        forceEmptyClass($el, "c-input--empty", date || time);

        if(date && time) {
          const dStr = moment(date+" "+time).toISOString()
          $el.val(dStr);
        } else {
          $el.val('');
        }
      }

      $datePicker.on('change', (dt)=>{
        date = $datePicker.val();
        updateDT();
      });
      $timePicker.on('change', (dt)=>{
        time = $timePicker.val();
        updateDT();
      });

      $($datePicker).materialDatePicker({
        ...defaultOptions,
        'time': false,
        'date': true,
        'format': 'YYYY-MM-DD'
      });

      $($timePicker).materialDatePicker({
        ...defaultOptions,
        'time': true,
        'date': false,
        'format': 'HH:mm'
      });
    } else {
      const $container = $(el).parent();
      $el.attr('type','hidden');
      const $dateDisabled = $('<input type="text" disabled="disabled" class="c-input--text c-input--datetime-disabled">');
      $container.append($dateDisabled);

      $dateDisabled.val(moment($el.val()).format("YYYY-MM-DD HH:mm"));
    }
  });
});
