const fallbackTranslations = {
  'datetimepicker-calendarweek': 'CW'
}

const getTranslations = () => {
  return window.appConfig.translations || {};
}

export const translate = (id) => {
  if(id in getTranslations()) {
    return getTranslations()[id];
  } else {
    return fallbackTranslations[id];
  }
}

export const getLocale = () => {
  return window.appConfig.locale;
}
