import $ from 'jquery';

import {documentReady, elementReady} from '../ReadyListener';

import swal from 'sweetalert2'

import { executeElementReadyListenersFor } from '../ReadyListener'

export const openDialog = ({id}) => {
  const html = $('.c-dialog--content[data-dialog-id='+id+']').text();
  const width = $('.c-dialog--content[data-dialog-id='+id+']').data("dialog-width");

  swal({
    html: html,
    showConfirmButton: false,
    width: width,
    onOpen: (el) => {
      executeElementReadyListenersFor($(el));
    }
  })
};

export const closeDialog = ()=>{
  swal.close();
}

documentReady((body)=>{

});

elementReady(($root)=>{
  $root.findWithRoot('.c-dialog-button').each(function() {

  });
});