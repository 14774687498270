import $ from 'jquery';

import {documentReady, elementReady} from '../ReadyListener';

documentReady((body)=>{

});

elementReady(($root)=>{
    $root.findWithRoot('.c-checkbox--virtualbox').click(function() {
        $(this).prev().click();
    })
});