import $ from 'jquery';
import Inputmask from "inputmask";

import autosize from 'autosize';
import {setEmptyClass} from '../polyfills/input-empty';
import {documentReady, elementReady} from '../ReadyListener';

documentReady((body)=>{
  Inputmask.extendDefaults({
    placeholder: "",
    mask:"AAA-AAA"
  });
});

elementReady(($root)=>{
  //$root.find("input").val("test");
  //Inputmask().mask($root.find("input[type=text]"));

  $root.findWithRoot('.c-input--text').each((i,el)=>{
    const $el = $(el);
    const suggestUrl = $el.data('suggest-url');
    const suggestSourceId = $el.data('suggest-source-id');
    const $suggestSource = $('#'+suggestSourceId);

    if(suggestUrl && $suggestSource.length>0) {
      $el.on('blur',()=>{
        if($el.val().length>0) {
          $el.data('manually-changed', true);
        }
      })
      $suggestSource.on('blur',()=>{
        const manuallyChanged = $(el).data('manually-changed');
        if(!manuallyChanged) {
          $.getJSON(suggestUrl,{q: $suggestSource.val()},(resp)=>{
            $el.val(resp.suggestion);
            setEmptyClass($el,"c-input--empty");
          })
        }
      });
    }
  })

  autosize($root.findWithRoot('textarea'));
});
