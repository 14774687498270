import $ from 'jquery';
import {documentReady, elementReady} from '../ReadyListener';

export const setEmptyClass = (element, cls) => {
  if($(element).val()) {
    $(element).removeClass(cls)
  } else {
    $(element).addClass(cls)
  }
  $(element).parent().removeClass("tmpierepaintfix");
  $(element).parent().addClass("tmpierepaintfix");
  $(element).parent().removeClass("tmpierepaintfix");
};

documentReady(($body) => {
  $body.on('change', 'input', function() {
    setEmptyClass(this, "c-input--empty");
  });
  $body.on('keyup', 'input', function() {
    setEmptyClass(this, "c-input--empty");
  });

  $body.on('change', 'textarea', function() {
    setEmptyClass(this, "c-textarea--empty");
  });
  $body.on('keyup', 'textarea', function() {
    setEmptyClass(this, "c-textarea--empty");
  });

  let loadingAutocompleteFix = setTimeout(function() {
    $body.find("input").each(function(i, el) {
      setEmptyClass(el, "c-input--empty");
    });
  }, 100);
});

elementReady(($root)=>{
  $root.find("input").each((i,el)=>{
    setEmptyClass(el, "c-input--empty");
  })

  $root.find("input").each(function(i, el) {
    setEmptyClass(el, "c-input--empty");
  });

  $root.find("textarea").each(function(i, el) {
    setEmptyClass(el, "c-textarea--empty");
  });
})


export const forceEmptyClass = (element, cls, val) => {
  if(val) {
    $(element).removeClass(cls)
  } else {
    $(element).addClass(cls)
  }
  $(element).parent().removeClass("tmpierepaintfix");
  $(element).parent().addClass("tmpierepaintfix");
  $(element).parent().removeClass("tmpierepaintfix");
}